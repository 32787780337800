<template>
  <form @submit.prevent="addSite">
    <modal-card :title="'Add new site'" :processing="processing">
      <b-message type="is-dark"
        >Enter or paste your website URL and choose your software type. You can
        change these later.</b-message
      >

      <site-url-field
        :disabled="processing"
        required
        autofocus
        @change="form.url = $event"
      />

      <site-software-field
        :disabled="processing"
        required
        @change="form.software = $event"
      />

      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="formIsDisabled"
        type="submit"
        class="button is-success"
      >
        Add site
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "AddSiteModal",
  components: {
    "site-url-field": () => import("@shared/sites/_siteUrlField"),
    "site-software-field": () => import("@shared/sites/_siteSoftwareField")
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      processing: false,
      form: {
        url: null,
        software: null
      }
    };
  },
  computed: {
    formIsValid() {
      if (!this.$_.isObject(this.form.url)) return false;
      if (!this.form.url.fqdn) return false;
      if (!this.form.url.protocol) return false;
      if (!this.form.url.url) return false;
      if (!this.form.software) return false;
      return true;
    },
    formIsDisabled() {
      return this.processing || !this.formIsValid;
    }
  },
  methods: {
    addSite() {
      if (!this.formIsDisabled) {
        this.processing = true;
        this.$store
          .dispatch("sites/addSite", {
            fqdn: this.form.url.fqdn,
            url: this.form.url.url,
            software: this.form.software,
            userId: this.userId
          })
          .then(result => {
            this.$emit("success", result.data.siteId);
            this.$toast.open({
              message: result.message
            });
            // this.$router.push(`/sites/${result.data.siteId}`);
          })
          .catch(error => {
            this.processing = false;
            this.$toast.open({
              message: error.message,
              type: "is-danger"
            });
          });
      }
    }
  }
};
</script>
